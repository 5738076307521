import "./index.css";
import { useRef } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  window.scrollTo(0, 0);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_rxuzj4e",
        "template_o9r25h3",
        form.current,
        "whR5XjAzU3J1LDTwj"
      )
      .then(
        (result) => {
          console.log(result.text);
          window.location.reload();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact">
      <div className="contact__text">
        <p className="contact__text-bold" style={{ letterSpacing: "5px" }}>
          General Inquiries
        </p>
        <p
          className="contact__text-bold"
          style={{ textDecoration: "underline", fontWeight: "300" }}
        >
          benjamine.cadette@gmail.com
        </p>
        <div className="contact__logo">
          <a href="/">
            <img
              className="contact__logo--small"
              src="/img/benjamine-logo.jpg"
              alt="instagram"
            />
          </a>
          <a href="https://www.instagram.com/benjamine_cadette/?hl=en">
            <img
              className="contact__logo--small"
              alt="benjamine"
              src="/img/instagram.jpg"
            />
          </a>
        </div>
      </div>
      <form ref={form} onSubmit={sendEmail} className="contact__form">
        <p className="contact__title" style={{ letterSpacing: "5px" }}>
          GET IN TOUCH
        </p>
        <label className="contact__label">
          Name
          <span
            style={{
              fontSize: "0.7rem",
              left: "5px",
              position: "relative",
              bottom: "5px",
            }}
          >
            *
          </span>
        </label>
        <br />
        <input className="contact__name" name="firstName" />
        <input className="contact__name" name="lastName" />
        <div className="contact__name-bottom">First Name</div>
        <div className="contact__name-bottom">Last Name</div>
        <label className="contact__label">
          Email Address
          <span
            style={{
              fontSize: "0.7rem",
              left: "5px",
              position: "relative",
              bottom: "5px",
            }}
          >
            *
          </span>
        </label>
        <input className="contact__input-100" name="email" type="text" />
        <label className="contact__label">
          Subject
          <span
            style={{
              fontSize: "0.7rem",
              left: "5px",
              position: "relative",
              bottom: "5px",
            }}
          >
            *
          </span>
        </label>
        <input className="contact__input-100" name="subject" type="text" />
        <label className="contact__label">
          Message
          <span
            style={{
              fontSize: "0.7rem",
              left: "5px",
              position: "relative",
              bottom: "5px",
            }}
          >
            *
          </span>
        </label>
        <textarea
          className="contact__input-100 contact__input-100--text-area"
          name="message"
          type="text"
          maxLength="300"
        />
        <button className="contact__submit" type="submit">
          SUBMIT
        </button>
      </form>
    </div>
  );
};

export default Contact;
