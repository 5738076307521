const data = {
  1: {
    title: "White dreams",
    lastImage: 9,
  },
  2: {
    title: "La mer romantique",
    lastImage: 7,
  },
  3: {
    title: "Individual harmonic",
    lastImage: 8,
  },
  4: {
    title: "Cultural patterning",
    lastImage: 6,
  },
  5: {
    title: "My name",
    lastImage: 9,
  },
  6: {
    title: "Whimsical airing",
    lastImage: 5,
  },
  7: {
    title: "In full swing",
    lastImage: 6,
  },
  8: {
    title: "Shadow space",
    lastImage: 7,
  },
  9: {
    title: "Rouge 82",
    lastImage: 3,
  },
  10: {
    title: "Turn up brown",
    lastImage: 7,
  },
  11: {
    title: "Purplish Scent",
    lastImage: 5,
  },
  12: {
    title: "Summer siesta",
    lastImage: 4,
  },
  13: {
    title: "Pollinator garden",
    lastImage: 7,
  },
  14: {
    title: "Selfish side",
    lastImage: 8,
  },
  15: {
    title: "Plenty, Glamour, Grace",
    lastImage: 5,
  },
  16: {
    title: "Between red and white",
    lastImage: 5,
  },
  17: {
    title: "Sans toi",
    lastImage: 7,
  },
  18: {
    title: "The weight of movement",
    lastImage: 5,
  },
  19: {
    title: "Poignant weight",
    lastImage: 5,
  },
  20: {
    title: "Angular collage",
    lastImage: 9,
  },
  21: {
    title: "Minhwa",
    lastImage: 5,
  },
  22: {
    title: "Cloud Pink",
    lastImage: 4,
  },
  23: {
    title: "Line to play",
    lastImage: 7,
  },
  24: {
    title: "Verdant green",
    lastImage: 3,
  },
  25: {
    title: "Modern flowing",
    lastImage: 5,
  },
  26: {
    title: "Summer white",
    lastImage: 3,
  },
  27: {
    title: "Out of order",
    lastImage: 7,
  },
  28: {
    title: "Daylight gesture",
    lastImage: 3,
  },
};

export default data;
