import "./index.css";

function About() {
  window.scrollTo(0, 0);
  return (
    <div className="about">
      <div className="about__text-wrap">
        <p className="about__text-top">A journey and A return</p>
        <p className="about__text-bottom">
          Hyoung Ook Choi, Founder and Creative Director of Benjamine Cadette
          (established 2010),
          <br />
          built his fashion career after completing his training at ESMOD
          (Seoul, Korea) and
          <br />
          L’Ecole de la Chambre Syndicale de la Couture Parisienne (Paris,
          France).
        </p>
        <p className="about__text-bottom">
          After having given much time to design and entrepreneurship,
          <br />
          he is returning to his creative origins and re-defining beauty for
          himself as an artist and designer.
        </p>
      </div>
      <div className="about__img-wrap">
        <img className="about__img" src="/img/about.jpg" alt="benjamine"></img>
      </div>
    </div>
  );
}

export default About;
