import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./header";
import { Main } from "./main";
import DetailPage from "./detail-page";
import CategoryPage from "./cadette";
import About from "./about";
import Contact from "./contact";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/detail/:id" element={<DetailPage />} />
          <Route path="/category/:id" element={<CategoryPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
