import "./index.css";
import { Link } from "react-router-dom";

function Image(props) {
  const style = {
    width: props.width,
    position: "absolute",
    top: props.top,
    left: props.left,
  };
  return (
    <Link to={props.link}>
      <img
        className="footer__image"
        src={props.image}
        alt="benjamine"
        style={style}
      />
    </Link>
  );
}

function Footer() {
  return (
    <div>
      <div className="footer">
        <div className="image-wrap">
          <Image
            image="/img/19.jpg"
            width="20vw"
            top="10vw"
            left="15vw"
            link="/detail/19"
          />
          <Image
            image="/img/20.jpg"
            width="15vw"
            top="0"
            left="36vw"
            link="/detail/20"
          />
          <Image
            image="/img/21.jpg"
            width="20vw"
            top="15vw"
            left="52vw"
            link="/detail/21"
          />
          <Image
            image="/img/22.jpg"
            width="25vw"
            top="38vw"
            left="0vw"
            link="/detail/22"
          />
          <Image
            image="/img/23.jpg"
            width="20vw"
            top="39vw"
            left="31vw"
            link="/detail/23"
          />
          <Image
            image="/img/24.jpg"
            width="25vw"
            top="42vw"
            left="60vw"
            link="/detail/24"
          />
          <Image
            image="/img/25.jpg"
            width="15vw"
            top="60vw"
            left="15vw"
            link="/detail/25"
          />
          <Image
            image="/img/26.jpg"
            width="23vw"
            top="68vw"
            left="35vw"
            link="/detail/26"
          />
          <Image
            image="/img/27-1.jpg"
            width="15vw"
            top="85vw"
            left="4vw"
            link="/detail/27"
          />
          <Image
            image="/img/27-2.jpg"
            width="15vw"
            top="85vw"
            left="19vw"
            link="/detail/27"
          />
          <Image
            image="/img/28.jpg"
            width="25vw"
            top="80vw"
            left="60vw"
            link="/detail/28"
          />
        </div>
      </div>
      <FooterMobile />
    </div>
  );
}

function FooterMobile() {
  return (
    <div className="footer-mobile">
      <div className="image-wrap">
        <Image
          image="/img/19.jpg"
          width="20vw"
          top="10vw"
          left="15vw"
          link="/detail/19"
        />
        <Image
          image="/img/20.jpg"
          width="15vw"
          top="0"
          left="36vw"
          link="/detail/20"
        />
        <Image
          image="/img/21.jpg"
          width="20vw"
          top="15vw"
          left="52vw"
          link="/detail/21"
        />
        <Image
          image="/img/22.jpg"
          width="25vw"
          top="38vw"
          left="0vw"
          link="/detail/22"
        />
        <Image
          image="/img/23.jpg"
          width="20vw"
          top="39vw"
          left="31vw"
          link="/detail/23"
        />
      </div>
      <div className="image-wrap">
        <Image
          image="/img/24.jpg"
          width="25vw"
          top="42vw"
          left="60vw"
          link="/detail/24"
        />
        <Image
          image="/img/25.jpg"
          width="15vw"
          top="60vw"
          left="15vw"
          link="/detail/25"
        />
        <Image
          image="/img/26.jpg"
          width="23vw"
          top="68vw"
          left="35vw"
          link="/detail/26"
        />
        <Image
          image="/img/27-1.jpg"
          width="15vw"
          top="85vw"
          left="4vw"
          link="/detail/27"
        />
        <Image
          image="/img/28.jpg"
          width="25vw"
          top="80vw"
          left="60vw"
          link="/detail/28"
        />
      </div>
    </div>
  );
}

export default Footer;
