import "./index.css";
import { Link } from "react-router-dom";
import { useState } from "react";

function MenuModal(props) {
  function closeModal() {
    props.setVisible(false);
  }

  return (
    <div className="modal">
      <Link to="/about" onClick={closeModal}>
        About
      </Link>
      <Link to="/category/cadette" onClick={closeModal}>
        Benjamine Cadette
      </Link>
      <Link to="/category/garden" onClick={closeModal}>
        Benjamine Garden
      </Link>
      <Link to="/contact" onClick={closeModal}>
        Contact
      </Link>
      <div style={{ height: "50px" }}></div>
      <div onClick={closeModal}>X</div>
    </div>
  );
}

function Mobile(props) {
  return (
    <div className="mobile-menu" onClick={() => props.setVisible(true)}>
      <hr />
      <hr />
      <hr />
    </div>
  );
}

function Header() {
  const [visible, setVisible] = useState(false);

  return (
    <header>
      <Link class="logo" to="/">
        Benjamine <br /> Cadette
      </Link>
      <div className="menu">
        <Link className="nav" to="/about">
          About
        </Link>
        <ul className="menu_drop nav">
          Work
          <ul>
            <li>
              <Link to="/category/cadette">Benjamine Cadette</Link>
            </li>
            <li>
              <Link to="/category/garden">Benjamine Garden</Link>
            </li>
          </ul>
        </ul>
        <Link className="nav" to="/contact">
          Contact
        </Link>
      </div>
      <Mobile setVisible={setVisible} />
      {visible && <MenuModal setVisible={setVisible} />}
    </header>
  );
}
export default Header;
