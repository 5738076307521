import "./index.css";
import { Link } from "react-router-dom";
import Footer from "../footer";
import Modal from "react-modal";
import {useState} from 'react';



function TwiceBanner(props) {
  const leftStyle = {
    width: props.width,
  };
  const rightStyle = {
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
    width: props.width,
  };
  return (
    <div className="twice-banner">
      <div className="twice-banner__left" style={leftStyle}>
        <Link to={props.leftLink}>
          <img
            className="twice-banner__left-image"
            src={props.leftImage}
            alt="benjamine"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="twice-banner__right" style={rightStyle}>
        <Link to={props.rightLink}>
          <img
            className="twice-banner__right-image"
            src={props.rightImage}
            alt="benjamine"
            loading="lazy"
          />
        </Link>
      </div>
    </div>
  );
}

function LeftBanner(props) {
  const leftStyle = {
    width: props.width,
  };
  const rightStyle = {
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
    width: props.width,
  };
  return (
    <div className="left-banner">
      <div className="left-banner__left" style={leftStyle}>
        <Link to={props.leftLink}>
          <img
            className="left-banner__left-image"
            src={props.leftImage}
            alt="benjamine"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="left-banner__right" style={rightStyle}>
        <Link to={props.rightLink}>
          <img
            className="left-banner__right-image"
            src={props.rightImage}
            alt="benjamine"
            loading="lazy"
          />
        </Link>
      </div>
    </div>
  );
}

function RightBanner(props) {
  const leftStyle = {
    width: props.width,
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
  };
  const rightStyle = {
    width: props.width,
  };
  return (
    <div className="right-banner">
      <div className="right-banner__left" style={leftStyle}>
        <Link to={props.leftLink}>
          <img
            className="right-banner__left-image"
            src={props.leftImage}
            style={{ width: props.leftWidth }}
            alt="benjamine"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="right-banner__right" style={rightStyle}>
        <Link to={props.rightLink}>
          <img
            className="right-banner__right-image"
            src={props.rightImage}
            alt="benjamine"
            loading="lazy"
          />
        </Link>
      </div>
    </div>
  );
}

function MiddleBanner(props) {
  return (
    <div className="middle-banner">
      <div className="middle-banner__left">
        <Link to={props.leftLink}>
          <img
            className="middle-banner__left-image"
            src={props.leftImage}
            alt="benjamine"
            loading="lazy"
          />
        </Link>
      </div>
      <div className="middle-banner__right">
        <Link to={props.rightLink}>
          <img
            className="middle-banner__right-image"
            src={props.rightImage}
            alt="benjamine"
            loading="lazy"
          />
        </Link>
      </div>
    </div>
  );
}

function Bottom() {
  return (
    <div>
      <LeftBanner
        leftImage="/img/1.jpg"
        rightImage="/img/2.jpg"
        leftLink="/detail/1"
        rightLink="/detail/2"
      />
      <RightBanner
        leftImage="/img/3.png"
        rightImage="/img/4.jpg"
        leftLink="/detail/3"
        rightLink="/detail/4"
        leftWidth="20vw"
      />
      <div className="banner-option1">
        <Link to="/detail/5">
          <img
            className="banner-option1__image"
            src="/img/5-1.jpg"
            alt="benjamine"
            loading="lazy"
          />
        </Link>
        <Link to="/detail/5">
          <img
            className="banner-option1__image"
            src="/img/5-2.jpg"
            alt="benjamine"
            loading="lazy"
          />
        </Link>
        <Link to="/detail/5">
          <img
            className="banner-option1__image"
            src="/img/5-3.jpg"
            alt="benjamine"
            loading="lazy"
          />
        </Link>
      </div>
      <LeftBanner
        leftImage="/img/6.jpg"
        rightImage="/img/7.jpg"
        leftLink="/detail/6"
        rightLink="/detail/7"
      />
      <div className="full-banner">
        <Link to="/detail/8">
          <img
            className="full-banner__image"
            src="/img/8.jpg"
            alt="benjamine"
          />
        </Link>
      </div>
      <div className="full-banner">
        <Link to="/detail/9">
          <img
            className="full-banner__image"
            src="/img/9.jpg"
            alt="benjamine"
          />
        </Link>
      </div>
      <RightBanner
        leftImage="/img/10.jpg"
        leftLink="/detail/10"
        rightImage="/img/11.jpg"
        rightLink="/detail/11"
      />
      <MiddleBanner
        leftImage="/img/12-1.jpg"
        leftLink="/detail/12"
        rightImage="/img/12-2.jpg"
        rightLink="/detail/12"
      />
      <RightBanner
        leftImage="/img/13.jpg"
        leftLink="/detail/13"
        rightImage="/img/14.jpg"
        rightLink="/detail/14"
      />
      <LeftBanner
        leftImage="/img/15.jpg"
        leftLink="/detail/15"
        rightImage="/img/16.jpg"
        rightLink="/detail/16"
        justifyContent="flex-end"
      />
      <RightBanner
        leftImage="/img/17.jpg"
        leftLink="/detail/17"
        rightImage="/img/18.jpg"
        rightLink="/detail/18"
        justifyContent="flex-start"
        alignItems="flex-end"
      />
      <Footer />
    </div>
  );
}

function Main() {
  window.scrollTo(0, 0);
  let today = new Date();
  let lastDay = new Date('2023-11-05');
  let [popUp, setPopUp] = useState(true);

  function ClosePopUp(){
    setPopUp(false);
  }


  return (
    <div className="main">
      {today < lastDay&& <Modal style={{content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },}} isOpen={popUp}>
        <img className="pop-up" src="img/invitation.jpg"/>
        <div style={{cursor: "pointer"}} onClick={ClosePopUp}>Close</div>
      </Modal>}
      <div style={{ paddingTop: "7vw" }}></div>
      <div className="banner">
        <div className="banner__left">
          <span className="banner__text">My own defining of beauty.</span>
        </div>
        <div className="banner__right">
          <img
            className="banner__img"
            src="/img/banner.jpg"
            alt="banner"
            loading="lazy"
          />
        </div>
      </div>
      <Bottom />
    </div>
  );
}

export { Main, LeftBanner, RightBanner, Bottom, TwiceBanner };
