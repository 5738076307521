import { LeftBanner, RightBanner, TwiceBanner } from "../main";
import data from "../data/database";
import { useParams } from "react-router-dom";
import { Bottom } from "../main";

function DetailPage() {
  window.scrollTo(0, 0);
  const { id } = useParams();
  const lastImage = data[id]["lastImage"];
  let images = [];
  if (lastImage > 0)
    images.push(
      <RightBanner
        key={1}
        leftImage={`/img/${id}/1.jpg`}
        rightImage={lastImage > 1 ? `/img/${id}/2.jpg` : ""}
        width="50%"
      />
    );

  if (lastImage > 2)
    images.push(
      <div key={2} style={{ padding: "1vw 5vw" }}>
        <div
          style={{
            backgroundColor: "rgb(250, 250, 250)",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img
            src={`/img/${id}/3.jpg`}
            alt="benjamine"
            style={{ width: "60%" }}
          />
        </div>
      </div>
    );

  if (lastImage > 3)
    images.push(
      <RightBanner
        key={3}
        leftImage={`/img/${id}/4.jpg`}
        rightImage={lastImage > 4 ? `/img/${id}/5.jpg` : ""}
        width="50%"
      />
    );

  if (lastImage > 5)
    images.push(
      <LeftBanner
        key={4}
        leftImage={`/img/${id}/6.jpg`}
        rightImage={lastImage > 6 ? `/img/${id}/7.jpg` : ""}
        width="50%"
      />
    );

  if (lastImage > 7)
    images.push(
      <div style={{ padding: "1.5vw 5vw" }}>
        <TwiceBanner
          key={5}
          leftImage={`/img/${id}/8.jpg`}
          rightImage={lastImage > 8 ? `/img/${id}/9.jpg` : ""}
        />
      </div>
    );
  for (let i = 12; i <= lastImage; i = i + 2) {
    if (i % 4 === 1) {
      images.push(
        <RightBanner
          key={i}
          leftImage={`/img/${id}/${i - 1}.jpg`}
          rightImage={`/img/${id}/${i}.jpg`}
          width="50%"
        />
      );
    } else {
      images.push(
        <LeftBanner
          key={i}
          leftImage={`/img/${id}/${i - 1}.jpg`}
          rightImage={`/img/${id}/${i}.jpg`}
          width="50%"
        />
      );
    }
  }

  const style = {
    fontSize: "1.2rem",
    fontFamily: "benjamine-detail",
    marginBottom: "10vw",
  };
  return (
    <div className="detail-page">
      <div className="detail-page__text" style={style}>
        <div
          style={{
            padding: "0 5vw",
            paddingTop: "5vw",
            fontFamily: "benjamine-detail",
          }}
        >
          {data[id].title}
        </div>
        {images}
      </div>
      <Bottom />
    </div>
  );
}

export default DetailPage;
