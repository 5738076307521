import { LeftBanner, RightBanner, Bottom } from "../main";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import Footer from "../footer";

function Cadette() {
  return (
    <div>
      <RightBanner
        leftImage="/img/1.jpg"
        leftLink="/detail/1"
        rightImage="/img/2.jpg"
        rightLink="/detail/2"
        width="50%"
      />
      <LeftBanner
        leftImage="/img/4.jpg"
        leftLink="/detail/4"
        rightImage="/img/5-1.jpg"
        rightLink="/detail/5"
        width="50%"
      />
      <RightBanner
        leftImage="/img/6.jpg"
        leftLink="/detail/6"
        rightImage="/img/8-1.jpg"
        rightLink="/detail/8"
        width="50%"
      />
      <RightBanner
        leftImage="/img/9/1.jpg"
        leftLink="/detail/9"
        rightImage="/img/10.jpg"
        rightLink="/detail/10"
        width="50%"
      />
      <LeftBanner
        leftImage="/img/13.jpg"
        leftLink="/detail/13"
        rightImage="/img/14.jpg"
        rightLink="/detail/14"
        width="50%"
      />
      <RightBanner
        leftImage="/img/18.jpg"
        leftLink="/detail/18"
        rightImage="/img/20.jpg"
        rightLink="/detail/20"
        width="50%"
      />
      <RightBanner
        leftImage="/img/22.jpg"
        leftLink="/detail/22"
        rightImage="/img/23.jpg"
        rightLink="/detail/23"
        width="50%"
      />
      <LeftBanner
        leftImage="/img/25.jpg"
        leftLink="/detail/25"
        rightImage="/img/27-1.jpg"
        rightLink="/detail/27"
        width="50%"
      />
    </div>
  );
}

function Garden() {
  return (
    <div>
      <RightBanner
        leftImage="/img/3-1.jpg"
        leftLink="/detail/3"
        rightImage="/img/7.jpg"
        rightLink="/detail/7"
        width="50%"
      />
      <LeftBanner
        leftImage="/img/11.jpg"
        leftLink="/detail/11"
        rightImage="/img/12-1.jpg"
        rightLink="/detail/12"
        width="50%"
      />
      <RightBanner
        leftImage="/img/15.jpg"
        leftLink="/detail/15"
        rightImage="/img/16.jpg"
        rightLink="/detail/16"
        width="50%"
      />
      <RightBanner
        leftImage="/img/17.jpg"
        leftLink="/detail/17"
        rightImage="/img/19.jpg"
        rightLink="/detail/19"
        width="50%"
      />
      <LeftBanner
        leftImage="/img/21.jpg"
        leftLink="/detail/21"
        rightImage="/img/24.jpg"
        rightLink="/detail/24"
        width="50%"
      />
      <RightBanner
        leftImage="/img/26.jpg"
        leftLink="/detail/26"
        rightImage="/img/28.jpg"
        rightLink="/detail/28"
        width="50%"
      />
    </div>
  );
}

function CategoryPage() {
  window.scrollTo(0, 0);
  const { id } = useParams();
  const [cadette, setCadette] = useState(false);
  const [garden, setGarden] = useState(false);
  useEffect(() => {
    if (id === "cadette") {
      setCadette(true);
      setGarden(false);
    } else {
      setCadette(false);
      setGarden(true);
    }
  }, [id]);

  const style = {
    margin: "5vw 0 0 5vw",
    fontSize: "1.5rem",
  };
  const font = {
    fontFamily: "benjamine-detail",
  };
  return (
    <div className="detail-page">
      <div className="detail-page__text" style={style}>
        {cadette && <span style={font}>Benjamine Cadette</span>}
        {garden && <span style={font}>Benjamine Garden</span>}
      </div>
      {cadette && <Cadette />}
      {garden && <Garden />}
      <div style={{ marginTop: "10vw" }}>
        <Bottom />
      </div>
    </div>
  );
}

export default CategoryPage;
